import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  // if you build engiconnect then default open
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  // if you build vertical then default open
  // {
  //   path: 'home',
  //   loadChildren: () => import('./our-vertical/vertical/vertical.module').then( m => m.VerticalPageModule)
  // },
  {
    path: 'vertical',
    loadChildren: () => import('./our-vertical/vertical/vertical.module').then( m => m.VerticalPageModule)
  },
  {
    path: 'all-vertical',
    loadChildren: () => import('./our-vertical/all-vertical/all-vertical.module').then( m => m.AllVerticalPageModule)
  },
  {
    path: 'choose-vertical',
    loadChildren: () => import('./our-vertical/choose-vertical/choose-vertical.module').then( m => m.ChooseVerticalPageModule)
  },
  {
    path: 'vertical-all-sellers',
    loadChildren: () => import('./our-vertical/vertical-all-sellers/vertical-all-sellers.module').then( m => m.VerticalAllSellersPageModule)
  },
  {
    path: 'vertical-all-cetegory',
    loadChildren: () => import('./our-vertical/vertical-all-cetegory/vertical-all-cetegory.module').then( m => m.VerticalAllCetegoryPageModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./seller/seller-profile-preview/seller-profile-preview.module').then( m => m.SellerProfilePreviewPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./our-product/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./our-product/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'product-details/:slug',
    loadChildren: () => import('./our-product/product-details/product-details.module').then( m => m.ProductDetailsPageModule)
  },
  {
    path: 'buyer-chat-history',
    loadChildren: () => import('./buyer/buyer-chat-history/buyer-chat-history.module').then( m => m.BuyerChatHistoryPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'buyer-enquiries',
    loadChildren: () => import('./buyer/buyer-inquiries/buyer-inquiries.module').then( m => m.BuyerInquiriesPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'buyer-login',
    loadChildren: () => import('./buyer/buyer-login/buyer-login.module').then( m => m.BuyerLoginPageModule)
  },
  {
    path: 'buyer-profile',
    loadChildren: () => import('./buyer/buyer-profile/buyer-profile.module').then( m => m.BuyerProfilePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'buyer-profile-preview',
    loadChildren: () => import('./buyer/buyer-profile-preview/buyer-profile-preview.module').then( m => m.BuyerProfilePreviewPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'buyer-registration',
    loadChildren: () => import('./buyer/buyer-registration/buyer-registration.module').then( m => m.BuyerRegistrationPageModule)
  },
  {
    path: 'buyer-validation',
    loadChildren: () => import('./buyer/buyer-signup-validation/buyer-signup-validation.module').then( m => m.BuyerSignupValidationPageModule)
  },
  {
    path: 'change-email',
    loadChildren: () => import('./change-email/change-email.module').then( m => m.ChangeEmailPageModule)
  },
  {
    path: 'cms/:current_page',
    loadChildren: () => import('./cms/cms.module').then( m => m.CmsPageModule)
  },
  {
    path: 'engi-connect',
    loadChildren: () => import('./engiconnect/engiconnect.module').then( m => m.EngiconnectPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'feedback-thankyou',
    loadChildren: () => import('./feedback-thankyou/feedback-thankyou.module').then( m => m.FeedbackThankyouPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'transection-thankyou',
    loadChildren: () => import('./transuction-success/transuction-success.module').then( m => m.TransuctionSuccessPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'seller-your-products',
    loadChildren: () => import('./seller/product/seller-your-products/seller-your-products.module').then( m => m.SellerYourProductsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-add-product',
    loadChildren: () => import('./seller/product/seller-add-product/seller-add-product.module').then( m => m.SellerAddProductPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-banner-promotion',
    loadChildren: () => import('./seller/promotion/banner/seller-banner-promotion/seller-banner-promotion.module').then( m => m.SellerBannerPromotionPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-banner-book',
    loadChildren: () => import('./seller/promotion/banner/seller-banner-book/seller-banner-book.module').then( m => m.SellerBannerBookPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-brand-promotion',
    loadChildren: () => import('./seller/promotion/brand/seller-brand-promotion/seller-brand-promotion.module').then( m => m.SellerBrandPromotionPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-brand-book',
    loadChildren: () => import('./seller/promotion/brand/seller-brand-book/seller-brand-book.module').then( m => m.SellerBrandBookPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-product-promotion',
    loadChildren: () => import('./seller/promotion/product/seller-product-promotion/seller-product-promotion.module').then( m => m.SellerProductPromotionPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-product-book',
    loadChildren: () => import('./seller/promotion/product/seller-product-book/seller-product-book.module').then( m => m.SellerProductBookPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-promoted-products',
    loadChildren: () => import('./seller/promotion/promoted-products/seller-promoted-products/seller-promoted-products.module').then( m => m.SellerPromotedProductsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-promoted-product-book',
    loadChildren: () => import('./seller/promotion/promoted-products/seller-promoted-product-book/seller-promoted-product-book.module').then( m => m.SellerPromotedProductBookPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-featured-products',
    loadChildren: () => import('./seller/promotion/featured-products/seller-featured-products/seller-featured-products.module').then( m => m.SellerFeaturedProductsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-featured-book',
    loadChildren: () => import('./seller/promotion/featured-products/seller-featured-book/seller-featured-book.module').then( m => m.SellerFeaturedBookPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-transaction',
    loadChildren: () => import('./seller/seller-transaction/seller-transaction.module').then( m => m.SellerTransactionPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-chat-history',
    loadChildren: () => import('./seller/seller-chat-history/seller-chat-history.module').then( m => m.SellerChatHistoryPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-charges',
    loadChildren: () => import('./seller/seller-charges/seller-charges.module').then( m => m.SellerChargesPageModule)
  },
  {
    path: 'seller-inquiries',
    loadChildren: () => import('./seller/seller-inquiries/seller-inquiries.module').then( m => m.SellerInquiriesPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-most-inquired-products',
    loadChildren: () => import('./seller/seller-most-inquired-products/seller-most-inquired-products.module').then( m => m.SellerMostInquiredProductsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-verticals',
    loadChildren: () => import('./seller/seller-verticals/seller-verticals.module').then( m => m.SellerVerticalsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-upload-documents',
    loadChildren: () => import('./seller/seller-upload-documents/seller-upload-documents.module').then( m => m.SellerUploadDocumentsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'seller-profile',
    loadChildren: () => import('./seller/seller-profile/seller-profile.module').then( m => m.SellerProfilePageModule)
  },
  {
    path: 'seller-validation',
    loadChildren: () => import('./seller/seller-validation/seller-validation.module').then( m => m.SellerValidationPageModule)
  },
  {
    path: 'seller-resistration',
    loadChildren: () => import('./seller/seller-resistration/seller-resistration.module').then( m => m.SellerResistrationPageModule)
  },
  {
    path: 'seller-login',
    loadChildren: () => import('./seller/seller-login/seller-login.module').then( m => m.SellerLoginPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'seller-registration',
    loadChildren: () => import('./seller/seller-registration-form/seller-registration-form.module').then( m => m.SellerRegistrationFormPageModule)
  },
  {
    path: 'buyer-vertical',
    loadChildren: () => import('./buyer/buyer-vertical/buyer-vertical.module').then( m => m.BuyerVerticalPageModule)
    
  },
  {
    path: 'global-search',
    loadChildren: () => import('./global-search/global-search.module').then( m => m.GlobalSearchPageModule)
  },
  {
    path: 'promotion_cart',
    loadChildren: () => import('./payment/payment-overview/payment-overview.module').then( m => m.PaymentOverviewPageModule)
  },
  {
    path: 'summary-page',
    loadChildren: () => import('./payment/summary-page/summary-page.module').then( m => m.SummaryPagePageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./payment/address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./payment/coupon/coupon.module').then( m => m.CouponPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./seller/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'successfull-payment',
    loadChildren: () => import('./payment/successfull-payment/successfull-payment.module').then( m => m.SuccessfullPaymentPageModule)
  },
  {
    path: 'unsuccessfull-payment',
    loadChildren: () => import('./payment/unsuccessfull-payment/unsuccessfull-payment.module').then( m => m.UnsuccessfullPaymentPageModule)
  },
  {
    path: 'buyer-profile-global',
    loadChildren: () => import('./buyer/buyer-profile-global/seller-profile-global.module').then( m => m.SellerProfileGlobalPageModule)
  },
  // {
  //   path: 'promotion-cart',
  //   loadChildren: () => import('./payment/cart/cart.module').then( m => m.CartPageModule)
  // },
  {
    path: '**',
    loadChildren: () => import('./notfoundpage/notfoundpage.module').then( m => m.NotfoundpagePageModule)
  },


  




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
