import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(
    private http: HttpClient
  ) { }

  public redirectUrl: any;
  public loggedInStatus: any = localStorage.getItem('eepcLoggedIn');

  getHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  };

  getHeaderFileUpload() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  };
  // feedback
  buyerFeedback(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/create_feedback', data, this.getHeader())
  }
  // checking is logged in
  get isLoggedIn() {
    return localStorage.getItem('eepcLoggedIn');
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('eepcLoggedIn', 'true');
  }

  buyerLogin(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/buyer_login', data);
  }
  buyerRegistration(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/buyer_registration', data);
  }
  buyerOTPsend(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/send_otp_for_email', data);
  }
  buyerOTPvalidation(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/verify_otp_for_email', data);
  }
  tempBuyerRegistration(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/temporary_buyer_registration', data);
  }
  duplicateEmailvalidation(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/check_duplicate_email_and_mobile', data);
  }

  fetch_buyer_vertical(): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'buyer/fetch_buyer_vertical', this.getHeader());
  }
  fetchWishlist(vertical_id = ''): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'wishlist/fetch_wishlist' + queryString, this.getHeader());
  }
  daleteWishlist(id: any) {
    return this.http.delete(environment.appConfig.apiUrl + 'wishlist/delete_wishlist/' + id, this.getHeader());
  }
  createWishlist(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'wishlist/create_wishlist', data, this.getHeader());
  }
  fetchProductDetails(vertical_slug = '', product_slug = ''): Observable<any> {
    const params: any = {};
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug;
    }
    if (product_slug) {

      params["product_slug"] = product_slug;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_product_details' + queryString, this.getHeader());
  }
  createCart(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'cart/create_cart', data, this.getHeader());
  }

  fetchCart(vertical_id = ''): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    if (localStorage.getItem('temp_user_id')) {
      params["temp_user_id"] = localStorage.getItem('temp_user_id');
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    if (localStorage.getItem('temp_user_id')) {
      return this.http.get(environment.appConfig.apiUrl + 'cart/fetch_cart' + queryString);
    } else {
      return this.http.get(environment.appConfig.apiUrl + 'cart/fetch_cart' + queryString, this.getHeader());
    }
  }

  updateCart(cart_id = '', data: any): Observable<any> {
    return this.http.put(environment.appConfig.apiUrl + 'cart/update_cart/' + cart_id, data, this.getHeader());
  }

  deleteCart(cart_id: any): Observable<any> {
    return this.http.delete(environment.appConfig.apiUrl + 'cart/delete_cart/' + cart_id, this.getHeader());
  }

  fetchBuyerProfile() {
    return this.http.get(environment.appConfig.apiUrl + 'buyer/fetch_buyer_details', this.getHeader())
  }

  fetchBuyerPreviweProfile(buyer_id = '') {
    const params: any = {};
    if (buyer_id) {
      params["buyer_id"] = buyer_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_buyer_details' + queryString, this.getHeader())
  }

  fetch_buyer_profile_page(buyer_slug = '') {
    const params: any = {};
    if (buyer_slug) {
      params["buyer_slug"] = buyer_slug;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'buyer/fetch_buyer_profile_page' + queryString)
  }

  createOrder(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'order/create_order', data, this.getHeader())
  }

  fetchMostEnqueryProducts(vertical_id = '') {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'buyer/fetch_most_inquired_product_buyer' + queryString, this.getHeader())
  }
  fetchCountry() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_country')
  }
  updateBuyerProfile(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer', data, this.getHeaderFileUpload())
  }
  removeBuyerProfileImage(type = ''): Observable<any> {
    const params: any = {};
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.delete(environment.appConfig.apiUrl + 'buyer/delete_buyer_image' + queryString, this.getHeader());
  }
  changePasswordForBuyer(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/change_password', data);
  }
  forgotPassword(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'buyer/forgot_password', data, this.getHeader());
  }
  //Buyer Enquery Page
  fetchOrder(vertical_id = '', type = ''): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'order/fetch_order' + queryString, this.getHeader());
  }
  fetchOrderInquiry(vertical_id = '', type = '', skip = 0, limit = 10): Observable<any> {
    const params: any = {};
    params["skip"] = skip;
    params["limit"] = limit;
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'order/fetch_order2' + queryString, this.getHeader());
  }
  fetchOrderByOrderNo(order_no = ''): Observable<any> {
    const params: any = {};
    if (order_no) {
      params["order_no"] = order_no;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'order/fetch_order_by_order_no' + queryString, this.getHeader());
  }
  fetchOrderLineItem(order_no = '', type = ''): Observable<any> {
    const params: any = {};
    if (order_no) {
      params["order_no"] = order_no;
    }
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'order/fetch_order_line_item' + queryString, this.getHeader());
  }
  removeBuyerVerticals(vertical_id = '') {
    const body: any = {};
    
    if (vertical_id) {
      body["vertical_id"] = vertical_id;
    }
    console.log(body);
    return this.http.put(environment.appConfig.apiUrl + 'buyer/remove_buyer_vertical/' , body, this.getHeader());

  }
}
