import { Injectable,  NgZone, EventEmitter} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AlertController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


declare var require: any;
@Injectable()
export class Globals {

  pushObject;
  constructor(
    public alertController: AlertController,
    public toastCtrl: ToastController,
    private router:Router,
    private zone: NgZone
    ) {
      
  }

  public cart_value = new EventEmitter<String>()
  public chat_value = new EventEmitter<String>()
  public logout = new EventEmitter<boolean>()
  public profile_value = new EventEmitter<{}>()
  userCartCount: string = (localStorage.getItem('userCartCount') == null)? "0" : localStorage.getItem('userCartCount');
  updateCartCount(cartCount: string) {
    // localStorage.setItem('userCartCount', cartCount);
    this.userCartCount = cartCount;
  }
  
  userWishListCount: string = (localStorage.getItem('userWishListCount') == null)? "0" : localStorage.getItem('userWishListCount');
  updateWishlistCount(wishlistCount: string) {
    localStorage.setItem('userWishListCount', wishlistCount);
    this.userWishListCount = wishlistCount;
  }

  httpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem("user")?JSON.parse(localStorage.getItem("user"))['data']['token']:'')
      })
    }
  }

  httpOptionsWithoutContentType(): any {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + (localStorage.getItem("user")?JSON.parse(localStorage.getItem("user"))['data']['token']:'')
      })
    }
  }

  headerType = "home"
  updateHeaderType(type: string) {
    this.headerType = type;
  }
  async successAlert(message) {
    // const alert = await this.alertController.create({
    //   header: 'Alert',
    //   message: message,
    //   buttons: ['OK']
    // });

    // await alert.present();


    const toast = await this.toastCtrl.create({  
      message: message,
      duration: 5000,
      position: 'top' // Set position to right-top 
    });  
    toast.present();
    
  }
  async onlineStatus(message, color) {
    const toast = await this.toastCtrl.create({  
      message: message,
      duration: 5000,
      cssClass: 'custom-toast', // Apply the custom CSS class
      position: 'top', // Set position to right-top 
      color: color
    });  
    toast.present();
  }
  checkLogin(){
    if(localStorage.getItem("user")){
      return true;
    }else{
      return false;
    }
  }

  removePush() {
    this.pushObject.unregister();
    this.pushObject.on('unregister').subscribe((data: any) => {
    });
  }
  async presentConfirm(message,navigateUrl,params,navigateParamValue) {
    let alert = await this.alertController.create({
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            
          }
        },
        {
          text: 'View',
          handler: () => {
            this.router.navigate([navigateUrl], { queryParams: { params: navigateParamValue } });
            //this.router.navigate([navigateUrl]);
          }
        }
      ]
    });
    await alert.present();
  }

  buyer_chat = 0
  updateBuyerChatCount(chat_count){
    this.buyer_chat = chat_count;
  }

  seller_chat = 0
  updateSellerChatCount(chat_count){
    this.seller_chat = chat_count;
  }

  seller_notification = 0
  updateSellerNotificationCount(notification_count:any){
    this.seller_notification = notification_count;
  }

  buyer_notification = 0
  updateBuyerNotificationCount(notification_count:any){
    this.buyer_notification = notification_count;
  }
  login_organisation_name = localStorage.getItem('login_organisation_name') ? localStorage.getItem('login_organisation_name') : '';
  update_organisation_name = new BehaviorSubject<string>(this.login_organisation_name)
  changeOrganisationName(name: string){
    this.update_organisation_name.next(name);
  }

  login_organisation_logo = localStorage.getItem('login_organisation_logo') ? localStorage.getItem('login_organisation_logo') : '';
  update_organisation_logo = new BehaviorSubject<string>(this.login_organisation_logo);
  changeOrganisationLogo(image: string){
    this.update_organisation_logo.next(image);
  }

  login_type = localStorage.getItem('login_type');
  changeLoginType(type: string){
    this.login_type = type;
  }

  defaultName = localStorage.getItem('vertical_slug') ? localStorage.getItem('vertical_slug') : '';
  v_name = this.defaultName ? this.defaultName.replace(/-/g, ' ') : '';
  new_vertical_name = this.v_name ? this.v_name.toUpperCase() : '';
  selected_vertical_image = localStorage.getItem('vertical_img') ? localStorage.getItem('vertical_img') : '';

  objVertical = {
    select_vertical_name: this.new_vertical_name,
    selected_vertical_image: this.selected_vertical_image
  }
  selectHeaderVertical = new BehaviorSubject<object>(this.objVertical)
  updateVertical(value: object){
    this.selectHeaderVertical.next(value);
  }

  alpha_seller_name : any = ''
  alpha_seller_email : any = ''
}
