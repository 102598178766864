import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { BuyerService } from './services/buyer.service';
import { Globals } from './globals';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  selected_verticals: any = [];
  all_verticals: any = [];
  isVerticalLoading: boolean = false
  selectedVerticals: any = [];
  isSubmittingData: boolean = false;
  total_price: number = 0;
  order_no: string = ''
  globals: any;
  alpha_seller_name: any = ''
  alpha_seller_email: any = ''

  status: OnlineStatusType
  OnlineStatusType = OnlineStatusType;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private BuyerService: BuyerService,
    private onlineStatusService: OnlineStatusService,
    globals: Globals,

  ) {
    this.alpha_seller_name = localStorage.getItem('alpha_seller_name')
    this.alpha_seller_email = localStorage.getItem('alpha_seller_email')
    this.globals = globals

    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // use status
      this.status = status;
      this.globals.onlineStatus(this.status > 0 ? "Online" : 'Offline', this.status > 0 ? "success" : 'danger');
    });
  }

  ngOnInit(): void {
    let isLogin = localStorage.getItem('eepcLoggedIn');
    if (isLogin == 'true' && localStorage.getItem('login_type') == 'seller') {
      this.fetchVerticalData()
      setTimeout(() => {
        this.fetchAllVerticals()
      }, 200);
    }
    // let temp = localStorage.getItem("first_change_dropdown_vertical");
    // if (!temp) {
    //   localStorage.setItem("first_change_dropdown_vertical", "true");
    //   window.location.reload();
    // }else{
    //   localStorage.removeItem("first_change_dropdown_vertical");
    // }

    this.route.queryParams.subscribe((params: any) => {
      /* Get local storage data */
      if (params.token) {
        localStorage.setItem('token', params.token);
      } else {
        // localStorage.setItem('token', params.token);
      }
      if (params.login_data) {
        let login_data = JSON.parse(params.login_data);
        if (login_data.login_source) {
          localStorage.setItem('login_source', login_data.login_source)
        }
        if (login_data.currentUser) {
          localStorage.setItem('currentUser', login_data.currentUser);
        }
        if (login_data.token) {
          localStorage.setItem('token', login_data.token);
        }
        if (login_data.vertical_id) {
          localStorage.setItem('vertical_id', login_data.vertical_id);
        }
        if (login_data.vertical_slug) {
          localStorage.setItem('vertical_slug', login_data.vertical_slug);
        }
        if (login_data.vertical_img) {
          localStorage.setItem('vertical_img', login_data.vertical_img);
        }
        if (login_data.eepcLoggedIn) {
          localStorage.setItem('eepcLoggedIn', login_data.eepcLoggedIn);
        }
        if (login_data.temp_user_id) {
          localStorage.setItem('temp_user_id', login_data.temp_user_id);
        }
        if (login_data.login_type) {
          localStorage.setItem('login_type', login_data.login_type);
        }
        if (login_data.seller_slug) {
          localStorage.setItem('seller_slug', login_data.seller_slug);
        }
        if (login_data.seller_name) {
          localStorage.setItem('seller_name', login_data.seller_name);
        }
        if (login_data.vertical_name) {
          localStorage.setItem('vertical_name', login_data.vertical_name);
        }
        if (login_data.address_id) {
          localStorage.setItem('address_id', login_data.address_id);
        }
        if (login_data.user_id) {
          localStorage.setItem('user_id', login_data.user_id);
        }
        if (login_data.buyer_name) {
          localStorage.setItem('buyer_name', login_data.buyer_name);
        }
        if (login_data.buyer_company_name) {
          localStorage.setItem('buyer_company_name', login_data.buyer_company_name);
        }
        if (login_data.buyer_profile_img) {
          localStorage.setItem('buyer_profile_img', login_data.buyer_profile_img);
        }

        this.globals.changeOrganisationName(login_data.login_organisation_name);
        this.globals.changeOrganisationLogo(login_data.login_organisation_logo);
        this.globals.changeLoginType(login_data.login_type);

        if (login_data.currentUser) {
          let user = JSON.parse(login_data.currentUser);
          localStorage.setItem('login_organisation_name', user.login_organisation_name);
          localStorage.setItem('login_organisation_logo', user.login_organisation_logo);

          this.globals.changeOrganisationName(user.login_organisation_name);
          this.globals.changeOrganisationLogo(user.login_organisation_logo);
        }

        /* Header vertical name and image show */
        let defaultName = login_data.vertical_slug;
        let v_name = defaultName ? defaultName.replace(/-/g, ' ') : '';
        let new_vertical_name = v_name ? v_name.toUpperCase() : '';

        let objVertical = {
          select_vertical_name: new_vertical_name,
          selected_vertical_image: login_data.vertical_img
        }
        this.globals.updateVertical(objVertical);
        /* End Header vertical name and image show */

        this.globals.updateCartCount("0");

      }
    })
  }
  fetchVerticalData() {
    this.isVerticalLoading = true
    this.CommonService.fetch_user_vertical_detail().subscribe((res: any) => {
      if (res.status == 200) {
        this.isVerticalLoading = false
        this.selected_verticals = res.body
        // this.selected_verticals.map((item: any) => {
        //   this.selectedVerticals.push(item.id)
        // })
      } else {
        this.isVerticalLoading = false
        this.globals.successAlert(res.message ? res.message : 'Data not found!')
      }
    },
      (err: any) => {
        this.isVerticalLoading = false
        this.globals.successAlert(err.error.message ? err.error.message : 'Please try again later!')
      })
  }
  fetchAllVerticals() {
    this.CommonService.fetch_all_vertical('').subscribe((res: any) => {
      if (res.status == 200) {
        res.body.data.length && res.body.data.map((data: any) => {
          this.selected_verticals.map((item: any) => {
            if (item.id == data.id) {
              data['isExist'] = true
            }
          })
        })
        this.all_verticals = res.body.data

        // Temporary add price
        this.total_price = 0;
        this.all_verticals.length && this.all_verticals.map((response: any) => {
          let user: any = JSON.parse(localStorage.getItem('currentUser') || '');
          if (user.is_member == 'Yes') {
            response['price'] = response.member_price;
          } else {
            response['price'] = response.non_member_price;
          }

          if (response.isExist) {
            this.total_price += response.price;
          }
        })
      } else {
        this.globals.successAlert(res.message ? res.message : 'Data not found!')
      }
    },
      (err: any) => {
        this.globals.successAlert(err.error.message ? err.error.message : 'Please try again later!')
      })
  }
  selectVerticals(index: number) {
    if ($('#select_verticals-' + index).is(':checked')) {
      this.selectedVerticals.push(this.all_verticals[index].id);
      this.total_price += this.all_verticals[index].price;
    } else {
      let findeIndex = this.selectedVerticals.findIndex((item: any) => item === this.all_verticals[index].id);
      if (this.selectedVerticals.length > 0) {
        this.total_price = this.total_price - this.all_verticals[index].price;
        this.selectedVerticals.splice(findeIndex, 1);
      }
      // if (this.selectedVerticals.includes(this.all_verticals[index].id) !== -1) {
      //   this.selectedVerticals.splice(this.selectedVerticals.includes(this.all_verticals[index].id), 1);
      // }
    }
  }

  onClickPayNow() {
    this.isSubmittingData = true
    let data = {
      vertical_id: this.selectedVerticals
    }
    this.CommonService.update_user_vertical_detail(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.isSubmittingData = false;
        $('#more_verticals_popup').modal('hide');
        // this.fetchVerticalData()
        this.router.navigate(['/transection-thankyou'], { queryParams: { soure: "seller_vertical" } })
        this.globals.successAlert(res.message ? res.message : 'Data Submitted Successfully.')
      } else {
        this.isSubmittingData = false
        this.globals.successAlert(res.message ? res.message : 'Data Not Submitted!')
      }
    },
      (err: any) => {
        this.isSubmittingData = false
        this.globals.successAlert(err.error.message ? err.error.message : 'Please try again later!')
      })
  }
  closePopup() {
    $('#more_verticals_popup').modal('hide');
  }
  // seller charges popup start
  onClickContinue() {
    $('.modal').modal('hide')
    // this.is_new_eepc_seller = localStorage.getItem('new_seller')
    setTimeout(() => {
      $('#new_seller_modal').modal('show')
    }, 400);
    // if (this.is_new_eepc_seller == 'true') {
    // } else {
    //   this.route.navigate(['/seller-login'])
    // }
  }
  // seller charges popup end

  // cart popup start
  closePopupCart() {
    $('#inquiry_popup').modal('hide')
    this.router.navigate(['/home']);
  }
  // cart popup end


  onClickLogin() {
    $('#new_seller_modal').modal('hide')
    this.router.navigate(['/seller-login']);
  }

  cancelModal() {
    $('#logout_modal').modal('hide');
  }
  logout() {
    this.globals.logout.emit(true)
  }
}
