import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(
    private http: HttpClient
  ) { }

  public redirectUrl: any;
  public loggedInStatus: any = localStorage.getItem('eepcLoggedIn');

  getHeader() {
    if (localStorage.getItem('token')) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
      }
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('reg_token')
        })
      }
    }
  };

  getHeaderFileUpload() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  };
  // checking is logged in
  get isLoggedIn() {
    return localStorage.getItem('eepcLoggedIn');
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('eepcLoggedIn', 'true');
  }
  getAllOrganisationType() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_organisation_type')
  }
  updateSellerProfile(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'seller/update_seller', data, this.getHeaderFileUpload())
  }
  changePasswordForSeller(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/change_password', data);
  }
  forgotPasswordForSeller(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/forgot_password', data, this.getHeader());
  }
  removeSellerProfileImage(type = ''): Observable<any> {
    const params: any = {};
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.delete(environment.appConfig.apiUrl + 'seller/delete_seller_image' + queryString, this.getHeader());
  }
  // seller upload document
  uploadSellerDocument(file: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/upload_document', file, this.getHeaderFileUpload());
  }
  viewSellerDocument(): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_document', this.getHeader());
  }
  // seller transation history
  getTransactionHistory(vertical_id = '', skip = 0, limit = 10): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    params['skip'] = skip;
    params['limit'] = limit;
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'transaction/fetch_transaction' + queryString, this.getHeader());
  }

  // get most enquired product
  getAllMostEnruiredProduct(vertical_id = '', itc_hs_code = '', skip = 0, limit = 10): Observable<any> {
    const params: any = {};
    params['skip'] = skip;
    params['limit'] = limit;
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    if (itc_hs_code) {
      params["hs_code"] = itc_hs_code;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_seller_most_inquired_product' + queryString, this.getHeader())
  }

  getAllHsCode(vertical_id = ''): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_product_hs_code' + queryString, this.getHeader())
  }

  // promotion
  fetchPromotion(type: string, vertical_id = null, skip = 0, limit = 10) {
    let end_url = "";
    if (type == 'banner') {
      end_url = 'promotion/fetch_banner_promotion';
    }
    if (type == 'brand') {
      end_url = 'promotion/fetch_brand_promotion';
    }
    if (type == 'product') {
      end_url = 'promotion/fetch_product_promotion';
    }
    if (type == 'promoted_product') {
      end_url = 'promotion/fetch_promoted_product';
    }
    if (type == 'feature_product') {
      end_url = 'promotion/fetch_featured_product';
    }
    const params: any = {};
    params['skip'] = skip;
    params['limit'] = limit;
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + end_url + queryString, this.getHeader())
  }
  fetchPromotionPrice(type = '') {
    const params: any = {};
    if (type) {
      params["type"] = type;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'package/fetch_package' + queryString, this.getHeader())
  }
  fetch_seller_profile(vertical_slug = '', seller_slug = '') {
    const params: any = {};
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug;
    }
    if (seller_slug) {
      params["seller_slug"] = seller_slug;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_seller_profile_page' + queryString, this.getHeader())
  }
  createPromotion(type: string, data: any) {
    let end_url = "";
    if (type == 'banner') {
      end_url = 'promotion/create_banner_promotion';
    }
    if (type == 'brand_promotion') {
      end_url = 'promotion/create_brand_promotion';
    }
    if (type == 'product') {
      end_url = 'promotion/create_product_promotion';
    }

    if (type == 'promoted_product') {
      end_url = 'promotion/create_promoted_product';
    }

    if (type == 'featured_product') {
      end_url = 'promotion/create_featured_product';
    }

    return this.http.post(environment.appConfig.apiUrl + end_url, data, this.getHeaderFileUpload())
  }
  getAllCountry(data) {
    const params: any = {};
    if (data) {
      params["name"] = data;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_country' + queryString)
  }

  getAllState() {
    const params: any = {};
    params["name"] = "India";
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_state' + queryString)
  }

  getAllRegion(state = "") {
    const params: any = {};
    if (state) {
      params["state"] = state;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_region' + queryString)
  }

  getAllCity(country = '', state = '') {
    const params: any = {};
    if (country) params['country'] = country;
    if (state) params['state'] = state;
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_city' + queryString)
  }

  userRegistration(registration_data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'seller/seller_registration', registration_data)
  }

  getProfileDetails() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_seller_details', this.getHeader())
  }
  // fetchCity() {
  //   return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_city')
  // }
  fetchState() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_state')
  }
  fetchCountry() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_country')
  }

  // API Of Your Product Category
  getAllYourProduct(vertical_id = '', category_id = '', skip = 0, limit = 10): Observable<any> {
    const params: any = {};
    params['skip'] = skip;
    params['limit'] = limit;
    if (vertical_id) {
      params["vertical_slug"] = vertical_id;
    }
    if (category_id) {
      params["category_slug"] = category_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_product' + queryString, this.getHeader());
  }
  getAllSellerYourProduct(search_hs_code, promotion_type = '', vertical_slug = '', category_id = '', skip = 0, limit = 10): Observable<any> {
    const params: any = {};
    params['skip'] = skip;
    params['limit'] = limit;
    if (search_hs_code) {
      params["search"] = search_hs_code;
    }
    if (promotion_type) {
      params["promotion_type"] = promotion_type;
    }
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug;
    }
    if (category_id) {
      params["category_id"] = category_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_seller_product' + queryString, this.getHeader());
  }
  getAllCategory(vertical_id = ''): Observable<any> {
    const params: any = {};
    if (vertical_id) {
      params["vertical_slug"] = vertical_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'category/fetch_category' + queryString, this.getHeader());
  }

  daleteProduct(id: any) {
    return this.http.delete(environment.appConfig.apiUrl + 'product/delete_product/' + id, this.getHeaderFileUpload());
  }

  autoFillProductDetails(id: any): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_product/' + id, this.getHeaderFileUpload());
  }

  createProduct(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'product/create_product', data, this.getHeaderFileUpload());
  }
  updateProduct(id: any, data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'product/update_product/' + id, data, this.getHeaderFileUpload());
  }



  // < ------------------- Seller otp validation ------------------- >
  sendOtpForMobile(mobile: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/send_otp_for_mobile', mobile, this.getHeaderFileUpload());
  }
  verifyOtpForMobile(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/verify_otp_for_mobile', data, this.getHeaderFileUpload());
  }
  sendOtpForEmail(email: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/send_otp_for_email', email, this.getHeaderFileUpload());
  }
  verifyOtpForEmail(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/verify_otp_for_email', data, this.getHeaderFileUpload());
  }
  // < ------------------- Seller otp validation ------------------- >

  // ------- Example APIs -------->
  // getAdminUserInfo(searchKey = '', skip = 0, limit = 10) {
  //   const params: any = {};
  //   params["skip"] = skip;
  //   params["limit"] = limit;
  //   if (searchKey) {
  //     params["search"] = searchKey;
  //   }

  //   var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
  //   return this.http.get(environment.appConfig.apiUrl + 'admin/fetch_admin_user' + queryString)
  // }

  getMemberDetails(member_type = null, member_number = null) {
    const params: any = {};
    params["user_id"] = localStorage.getItem('user_id');
    if (member_type) {
      params["member_type"] = member_type;
    }
    if (member_number) {
      params["member_number"] = member_number;
    }
   
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_member_details' + queryString)
  }

  checkDuplicateEmailAndMobile(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'seller/check_duplicate_email_and_mobile', data)
  }

  fetchBannerPromotion(vertical_id = null) {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'promotion/fetch_banner_promotion' + queryString)
  }

  fetchBookedDate(data: any) {
    let obj = { ...data };
    const params: any = {};
    if (obj.promotion_type) params["promotion_type"] = obj.promotion_type;
    if (obj.package_id) params["package_id"] = obj.package_id

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'promotion/fetch_banner_booked_date' + queryString, this.getHeader())
  }

  createBrandPromotion(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'promotion/create_brand_promotion', data, this.getHeaderFileUpload())
  }
  // Check Vertical Permission
  checkVerticalPermission(vertical_id = '') {
    const params: any = {};
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/check_vertical_permission' + queryString, this.getHeader())
  }

  checkSellerGSTN(gstn = '') {
    const params: any = {};
    if (gstn) {
      params["gstn"] = gstn;
    }

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/verify_gstn' + queryString, this.getHeader())
  }
  // chatCount() {
  //   return this.http.get(environment.appConfig.apiUrl + 'chat/fetch_unread_chat_count', this.getHeader())
  // }
  getAllChatRoom(seller_id = '', buyer_id = '') {
    const params: any = {};
    params["vertical_id"] = localStorage.getItem('vertical_id');
    if (seller_id) {
      params["seller_id"] = seller_id;
    }
    if (buyer_id) {
      params["buyer_id"] = buyer_id;
    }

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'chat/fetch_chatroom' + queryString, this.getHeader())
  }

  temporarySellerRegistration(data) {
    return this.http.post(environment.appConfig.apiUrl + 'seller/temporary_seller_registration', data)
  }
  // promotion cart
  createAddress(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'contact/create_address', data, this.getHeader())
  }
  fetchAddress() {
    return this.http.get(environment.appConfig.apiUrl + 'contact/fetch_address', this.getHeader())
  }
  fetchAddressbyId(id: any) {
    return this.http.get(environment.appConfig.apiUrl + 'contact/fetch_address/' + id, this.getHeader())
  }
  deleteAddress(id: any) {
    return this.http.delete(environment.appConfig.apiUrl + 'contact/delete_address/' + id, this.getHeader())
  }
  updateaddress(id: any, data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'contact/update_address/' + id, data, this.getHeader())
  }
  fetchCartPromotion(type = '', vertical_id = '') {
    const params: any = {};
    if (type) {
      params["type"] = type;
    }
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    console.log(params);

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'promotion/fetch_pending_promotion' + queryString, this.getHeader())
  }
  deletePromotion(type = '', vertical_id = '', promotion_id = '') {
    const params: any = {};
    if (type) {
      params["type"] = type;
    }
    if (vertical_id) {
      params["vertical_id"] = vertical_id;
    }
    if (promotion_id) {
      params["promotion_id"] = promotion_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'promotion/remove_promotion_cart' + queryString, this.getHeader())
  }

  promotionPayment(address_id = '', type = '', vertical_id = '', vertical_slug ='') {
    const body: any = {};
    if (address_id) {
      body["address_id"] = address_id;
    }
    if (type) {
      body["type"] = type;
    }
    if (vertical_id) {
      body["vertical_id"] = vertical_id;
    }
    if(vertical_slug) {
      body["vertical_slug"] = vertical_slug;
    }
    return this.http.post(environment.appConfig.apiUrl + 'transaction/makePayment', body, this.getHeader());
  }
  FetchPromotionBookedDate(data: any) {
    let obj = { ...data };
    const params: any = {};
    if (obj.promotion_type) params["promotion_type"] = obj.promotion_type;
    if (obj.promotion_id) params["promotion_id"] = obj.promotion_id

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'promotion/fetch_promotion_booked_date' + queryString, this.getHeader())
  }
  fetchOrderdetails(transaction_id: any) {
    const params: any = {};
    if (transaction_id) {
      params["transaction_line_id"] = transaction_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'transaction/fetch_transaction_details' + queryString, this.getHeader())
  }
  //seller charges
  sellerChargesDetails(data: any) {
    const payload = {
      vertical_id: data
    };

    return this.http.post(environment.appConfig.apiUrl + 'package/fetch_vertical_wise_package', payload)
  }
  sellerCheckDuplicateData(data: any) {
    return this.http.post(environment.appConfig.apiUrl + 'seller/check_duplicate_data', data, this.getHeader())
  }

  fetchPreviousSellerDetails(user_id: any) {
    const params: any = {};
    if (user_id) {
      params["user_id"] = user_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_seller_data' + queryString)
  }
  // free vertical payment
  
  paymentSuccess(data: any,vertical_slug:any) {
    const params: any = {};
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.post(environment.appConfig.apiUrl + 'transaction/paymentSuccess' + queryString, data, this.getHeader())
  }

}
