import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SellerService } from './services/seller.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: SellerService
) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let authenticationService: any = this.authenticationService.isLoggedIn;
      let authenticationToken: any = localStorage.getItem('token');

      if (!authenticationToken) {
        let url: string = state.url;
        this.authenticationService.redirectUrl = url;
        this.router.navigate(['/home']);
        localStorage.clear();
        return false;
      } else {
        return true;
      }
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       let authenticationToken: any = localStorage.getItem('token');

  //       if (!authenticationToken) {
  //         let url: string = state.url;
  //         this.authenticationService.redirectUrl = url;
  //         this.router.navigate(['/home']);
  //         localStorage.clear();
  //         resolve(false);
  //       } else {
  //         resolve(true);
  //       }
  //     }, 2000); // Delay for 2 seconds
  //   });
  // }
  
}
