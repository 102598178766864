import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  custome_token: string = "";
  login_type: string = localStorage.getItem('login_type') ? localStorage.getItem('login_type') : '';
  isLogin: string = localStorage.getItem('eepcLoggedIn') ? localStorage.getItem('eepcLoggedIn') : '';

  sellerProfileImage: any = {
    seller_profile_img: localStorage.getItem('seller_profile_img') ? localStorage.getItem('seller_profile_img') : '',
    seller_name: localStorage.getItem('seller_name') ? localStorage.getItem('seller_name') : '',
  }
  updateSellerProfileImage = new BehaviorSubject<object>(this.sellerProfileImage);

  buyerProfileImage: any = {
    buyer_profile_img: localStorage.getItem('buyer_profile_img') ? localStorage.getItem('buyer_profile_img') : '',
    buyer_name: localStorage.getItem('buyer_name') ? localStorage.getItem('buyer_name') : '',
    buyer_company_name: localStorage.getItem('buyer_company_name') ? localStorage.getItem('buyer_company_name') : '',
  }

  updateBuyerProfileImage = new BehaviorSubject<object>(this.buyerProfileImage);
  eepcLoggedIn = localStorage.getItem('eepcLoggedIn') ? localStorage.getItem('eepcLoggedIn') : '';
  userLogin = new BehaviorSubject<string>(this.eepcLoggedIn);

  update_login_type = new BehaviorSubject<string>(this.login_type);
  checkLoginVertical = new BehaviorSubject<string>(this.isLogin);
  constructor(
    private http: HttpClient,
    private router: Router,
    private globals: Globals
  ) { }


  public redirectUrl: any;
  public loggedInStatus: any = localStorage.getItem('eepcLoggedIn');
  getHeader() {
    if (localStorage.getItem('token')) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
      }
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('reg_token')
        })
      }
    }
  };

  updateSellerImage(value: object) {
    this.updateSellerProfileImage.next(value);
  }

  updateBuyerImage(value: object) {
    this.updateBuyerProfileImage.next(value);
  }

  updateLoginStatus(value: string) {
    this.userLogin.next(value);
  }

  updateLoginType(value: string) {
    this.update_login_type.next(value);
  }

  updateLoginVertical(value: string) {
    this.checkLoginVertical.next(value);
  }

  getCustomHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.custome_token
      })
    }
  };

  getHeaderFileUpload() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  };

  // checking is logged in
  get isLoggedIn() {
    return localStorage.getItem('eepcLoggedIn');
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('eepcLoggedIn', 'true');
  }

  login(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'seller/seller_login', data);
  }

  fetch_all_vertical(user_id = ''): Observable<any> {
    const params: any = {};
    if (user_id) {
      params["user_id"] = user_id;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'vertical/fetch_vertical' + queryString, this.getHeader());
  }

  getAllSeller(slug = '', search = ''): Observable<any> {
    const params: any = {};
    if (slug != '') {
      params["vertical_slug"] = slug
    }
    if (search) {
      params["search"] = search;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_all_seller' + queryString, this.getHeader());
  }

  fetch_all_categories(vertical_id = ''): Observable<any> {
    const params: any = {};
    if (vertical_id != '') {
      params["vertical_id"] = vertical_id
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'category/fetch_category' + queryString, this.getHeader())
  }

  getVerticalById(id = ''): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'vertical/fetch_vertical/' + id, this.getHeader())
  }
  resetPassword(data: any, token: string, login_type: string) {
    let end_url = "";
    this.custome_token = token;
    if (login_type == 'buyer') {
      end_url = 'buyer/reset_password';
    }
    if (login_type == 'seller') {
      end_url = 'seller/reset_password';
    }
    return this.http.post(environment.appConfig.apiUrl + end_url, data, this.getCustomHeader())
  }

  fetch_all_products(vertical_slug = '', category_slug = '', sort_by = '', filter: any = null, skip = 0, limit = 12): Observable<any> {
    const params: any = {};
    params["skip"] = skip;
    params["limit"] = limit;
    if (category_slug) {
      params["category_slug"] = category_slug;
    }
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug;
    }
    if (sort_by) {
      params["sort"] = sort_by;
    }

    if (filter) {
      for (let filterData in filter) {
        if (filter[filterData].length) {
          let filterKey = filterData.split('_mobile')[0];
          params[filterKey] = JSON.stringify(filter[filterData]);
        }
      }
    }

    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/fetch_product' + queryString, this.getHeader())
  }
  getFilterProduct(vertical_slug = '', category_slug = ''): Observable<any> {
    const params: any = {};
    if (category_slug) {
      params["category_slug"] = category_slug
    }
    if (vertical_slug) {
      params["vertical_slug"] = vertical_slug
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'product/product_filter_data' + queryString, this.getHeader())
  }
  fetch_vertical_detail(slug = ''): Observable<any> {
    const params: any = {};
    if (slug != '') {
      params["slug"] = slug
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'vertical/fetch_vertical_details' + queryString, this.getHeader())
  }
  fetch_all_sellers(slug = '', search = ''): Observable<any> {
    const params: any = {};
    if (slug != '') {
      params["vertical_slug"] = slug
    }
    if (search) {
      params["search"] = search;
    }
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_all_seller' + queryString, this.getHeader())
  }
  fetch_all_homePageDetails(): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'home/fetch_home_page', this.getHeader())
  }
  fetch_footer(): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'home/fetch_footer', this.getHeader())
  }
  addMoreVertical() {
    return this.http.get(environment.appConfig.apiUrl + 'vertical/add_more_vertical', this.getHeader())
  }
  fetch_user_vertical_detail(): Observable<any> {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_seller_vertical', this.getHeader())
  }
  update_user_vertical_detail(data: any): Observable<any> {
    return this.http.post(environment.appConfig.apiUrl + 'promotion/add_seller_vertical', data, this.getHeader())
  }

  fecthNotifications() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/fetch_notifications', this.getHeader())
  }
  clearNotificationHistroy() {
    return this.http.get(environment.appConfig.apiUrl + 'seller/remove_notification_histroy', this.getHeader())
  }
  headerNotification() {
    return this.http.get(environment.appConfig.apiUrl + 'notification/fetch_notification', this.getHeader())
  }

  globalSearch(vertical_id = '', search_type = '', search = '', skip = 0, limit = 12) {
    const params: any = {};
    if (search_type != '') params["type"] = search_type;
    if (vertical_id != '') params["vertical_id"] = vertical_id;
    if (search) params["search"] = search;
    if ( skip >= 0 ) params["skip"] = skip;
    if (limit) params["limit"] = limit;
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'home/home_search' + queryString, this.getHeader())
  }

  update_buyer_vertical_detail(data: any): Observable<any> {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())
  }


  getCountryList(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  getStateList(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  getAddress(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  getWhatWord(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  updateAddress(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  deleteAddress(data: any) {
    return this.http.put(environment.appConfig.apiUrl + 'buyer/update_buyer_vertical', data, this.getHeader())

  }
  localStoreData(data) {
    return this.http.post(environment.appConfig.apiUrl + 'home/insert-localstorage-data', data);
  }
  localStoreGetData(unique_id = "") {
    const params: any = {};
    if (unique_id) params["unique_id"] = unique_id;
    var queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(environment.appConfig.apiUrl + 'home/fetch-localstorage-data' + queryString, this.getHeader())
  }

  initializeApp(): Observable<any> {
    const token = this.getTokenFromQueryParams();
    if (token) {
      localStorage.clear()
      localStorage.setItem('token', token);
      this.storeTokenFromQueryParamsLoginData();
      // this.removeQueryParams(); return of(true);
      return of(true);
    } else {
      let token = localStorage.getItem("token");
      if (!token || token == "null" || token == "undefined" || token == null || token == undefined) {
      let temp_user_id = localStorage.getItem("temp_user_id");
        if(!temp_user_id) this.router.navigate(['/home']);
        
        console.log("initializeApp", temp_user_id);
      }
      this.storeTokenFromQueryParamsLoginData();
      // localStorage.clear();
      return of(true);
    }

  }

  private getTokenFromQueryParams(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  }

  private storeTokenFromQueryParamsLoginData(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    let login_data: any = urlParams.get('login_data');
    if (login_data) {
      login_data = JSON.parse(login_data);
      if (login_data.login_source) {
        localStorage.setItem('login_source', login_data.login_source)
      }
      if (login_data.currentUser) {
        localStorage.setItem('currentUser', login_data.currentUser);
      }
      if (login_data.token) {
        localStorage.setItem('token', login_data.token);
      }
      if (login_data.vertical_id) {
        localStorage.setItem('vertical_id', login_data.vertical_id);
      }
      if (login_data.vertical_slug) {
        localStorage.setItem('vertical_slug', login_data.vertical_slug);
      }
      if (login_data.vertical_img) {
        localStorage.setItem('vertical_img', login_data.vertical_img);
      }
      if (login_data.eepcLoggedIn) {
        localStorage.setItem('eepcLoggedIn', login_data.eepcLoggedIn);
      }
      if (login_data.temp_user_id) {
        localStorage.setItem('temp_user_id', login_data.temp_user_id);
      }
      if (login_data.login_type) {
        localStorage.setItem('login_type', login_data.login_type);
      }
      if (login_data.seller_slug) {
        localStorage.setItem('seller_slug', login_data.seller_slug);
      }
      if (login_data.seller_name) {
        localStorage.setItem('seller_name', login_data.seller_name);
      }
      if (login_data.vertical_name) {
        localStorage.setItem('vertical_name', login_data.vertical_name);
      }
      if (login_data.address_id) {
        localStorage.setItem('address_id', login_data.address_id);
      }
      if (login_data.user_id) {
        localStorage.setItem('user_id', login_data.user_id);
      }
      if (login_data.buyer_name) {
        localStorage.setItem('buyer_name', login_data.buyer_name);
      }
      if (login_data.buyer_company_name) {
        localStorage.setItem('buyer_company_name', login_data.buyer_company_name);
      }
      if (login_data.buyer_profile_img) {
        localStorage.setItem('buyer_profile_img', login_data.buyer_profile_img);
      }

      this.globals.changeOrganisationName(login_data.login_organisation_name);
      this.globals.changeOrganisationLogo(login_data.login_organisation_logo);
      this.globals.changeLoginType(login_data.login_type);

      if (login_data.currentUser) {
        let user = JSON.parse(login_data.currentUser);
        localStorage.setItem('login_organisation_name', user.login_organisation_name);
        localStorage.setItem('login_organisation_logo', user.login_organisation_logo);
        this.globals.changeOrganisationName(user.login_organisation_name);
        this.globals.changeOrganisationLogo(user.login_organisation_logo);
      }

      /* Header vertical name and image show */
      let defaultName = login_data.vertical_slug;
      let v_name = defaultName ? defaultName.replace(/-/g, ' ') : '';
      let new_vertical_name = v_name ? v_name.toUpperCase() : '';

      let objVertical = {
        select_vertical_name: new_vertical_name,
        selected_vertical_image: login_data.vertical_img
      }
      this.globals.updateVertical(objVertical);
      /* End Header vertical name and image show */

      this.globals.updateCartCount("0");

    }

    return urlParams.get('login_data');
  }

  // private removeQueryParams() {
  //   const url = window.location.origin + window.location.pathname;
  //   window.history.replaceState({}, document.title, url);
  // }
}
