// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  appConfig: {
    // Development server
    // // apiUrl: "http://20.33.56.97:3006/api/v1/",
    // apiUrl: "https://api.engiconnect.com/api/v1/",
    // socketUrl: "https://api.engiconnect.com",
    // // current_domain: "http://20.33.56.97/eepc_v9/",
    // current_build_domain: "",
    // current_domain: "http://localhost:8100/",
    // // current_domain: "http://notionalsystems.in/eepc_v9/"
    // running_domain_status: "local",

    

    // Production server
    running_domain_status: "production",
    apiUrl: "https://api.engiconnect.com/api/v1/",
    socketUrl: "https://api.engiconnect.com",

    current_domain: "https://engiconnect.com/", 
    current_build_domain: "https://engiconnect.com/",

    // current_domain: "https://handtool.engiconnect.com/",
    // current_build_domain: "handtool", /* handtool, pump, fastener, bicycle */
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
