import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Globals } from './globals';
import { OnlineStatusModule } from 'ngx-online-status';
import { CommonService } from './services/common.service';

export function initializeApp(commonService: CommonService) { return (): Promise<any> => { return commonService.initializeApp().toPromise(); }; }

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HttpClientModule, IonicModule.forRoot(), AppRoutingModule, OnlineStatusModule],
  providers: [{ provide: APP_INITIALIZER, useFactory: initializeApp, deps: [CommonService], multi: true }, Globals,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
